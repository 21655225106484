import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Dropdown, Tooltip, Space } from 'Components';

import ModalContextProvider from 'Hooks/useModal';
import { observer } from 'mobx-react-lite';
import { useUsersTableControls } from './UsersTableControls.hooks';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import {
	GetUsersRequest,
	DynamicPermissionCode,
	PermissionCode
} from 'Services/Api/Users/Types';
import { useMemo } from 'react';
import { usePermission } from 'Components/Permission/Permission.hooks';
import UsersExportButton from './UsersExportButton';
import UsersTableControlsModal, {
	UsersTableControlsModalPropsBase
} from './UsersTableControlsModal';

export type UsersTableControlsProps = UsersTableControlsModalPropsBase & {
	params: GetUsersRequest | undefined;
	hasData: boolean;
};

const UsersTableControls = observer((props: UsersTableControlsProps) => {
	const { params, hasData, ...modalProps } = props;
	const permission = usePermission();
	const { t } = useTranslation();
	const {
		currentAction,
		currentActionLoading,
		onAutoActivate,
		onActivate,
		onDisable,
		onDelete,
		onCancel,
		onConfirm,
		onAddUser,
		onResetPassword,
//		onMove,
		onAddMultipleUsers,
		onRestore
	} = useUsersTableControls(props);

	const handleClick: MenuClickEventHandler = (e) => {
		if (e.key === 'single') {
			onAddUser();
			return;
		}
		onAddMultipleUsers();
	};

	const permissions = permission.getHasPermission({
		code: [PermissionCode.ExportUsers],
		dynamicCode: [
			DynamicPermissionCode.ActivateUser,
			DynamicPermissionCode.MoveUser,
			DynamicPermissionCode.DeleteUser,
			DynamicPermissionCode.ResetPassword,
			DynamicPermissionCode.AddUser,
			DynamicPermissionCode.RestoreUser
		]
	});

	const {
		permittedByCode: {
			[DynamicPermissionCode.AddUser]: isAddUserPermitted,
			[PermissionCode.ExportUsers]: isExportPermitted
		}
	} = permissions;

	const USER_ROWS_ACTIONS = useMemo(() => {
		const {
			permittedByCode: {
				[DynamicPermissionCode.ActivateUser]: isActivatePermitted,
				[DynamicPermissionCode.MoveUser]: isMovePermitted,
				[DynamicPermissionCode.DeleteUser]: isDeletePermitted,
				[DynamicPermissionCode.ResetPassword]: isResetPasswordPermitted,
				[DynamicPermissionCode.RestoreUser]: isRestorePermitted
			}
		} = permissions;
		return {
			NON_DELETED: [
				{
					tooltip: t(''),
					content: t('Auto Activate'),
					visible: true,
					onClick: onAutoActivate
				},
				{
					tooltip: t(
						'users.components.usersTableControls.activateDisabledTooltip'
					),
					content: t('users.components.usersTableControls.activate'),
					visible: isActivatePermitted,
					onClick: onActivate
				},
				{
					tooltip: t(
						'users.components.usersTableControls.inactivateDisabledTooltip'
					),
					content: t(
						'users.components.usersTableControls.inactivate'
					),
					visible: isActivatePermitted,
					onClick: onDisable
				},
				/*
				{
					tooltip: t(
						'users.components.usersTableControls.moveDisabledTooltip'
					),
					content: t('users.components.usersTableControls.move'),
					visible: isMovePermitted,
					onClick: onMove
				},
				*/
				{
					tooltip: t(
						'users.components.usersTableControls.deleteDisabledTooltip'
					),
					content: t('delete'),
					visible: isDeletePermitted,
					onClick: onDelete
				},
				{
					tooltip: t(
						'users.components.usersTableControls.resetPasswordDisabledTooltip'
					),
					content: t(
						'users.components.usersTableControls.resetPassword'
					),
					visible: isResetPasswordPermitted,
					onClick: onResetPassword
				}
			],
			DELETED: [
				{
					tooltip: t(
						'users.components.usersTableControls.restoreDisabledTooltip'
					),
					content: t('users.components.usersTableControls.restore'),
					visible: isRestorePermitted,
					onClick: onRestore
				}
			]
		};
		// eslint-disable-next-line
	//}, [permissions, onActivate, onDisable, onMove, onDelete, onResetPassword]);
}, [permissions, onActivate, onDisable, onDelete, onResetPassword]);

	const isDeletedList = Boolean(props.params?.filters.isDeleted);
	const isActionDisabled = !props.selectedRowKeys.length;
	return (
		<>
			<Space wrap>
				{!isDeletedList ? (
					<>
						{USER_ROWS_ACTIONS.NON_DELETED.map(
							(action, index) =>
								action.visible && (
									<Tooltip
										key={index}
										title={action.tooltip}
										enabled={isActionDisabled}
									>
										<Button
											shape="round"
											ghost
											type="primary"
											onClick={action.onClick}
											disabled={isActionDisabled}
										>
											{action.content}
										</Button>
									</Tooltip>
								)
						)}

						{isExportPermitted && (
							<UsersExportButton
								params={props.params}
								disabled={!props.hasData}
							/>
						)}

						{isAddUserPermitted && (
							<Dropdown
								trigger={['click']}
								arrow={false}
								overlay={
									<Menu onClick={handleClick}>
										<Menu.Item
											icon={<Icon type="plus" />}
											key="single"
										>
											{t('user.createUser.title')}
										</Menu.Item>
										<Menu.Item
											icon={<Icon type="plusEllipsis" />}
											key="multiple"
										>
											{t('users.addUsers.title')}
										</Menu.Item>
									</Menu>
								}
							>
								<Button type="primary" shape="round">
									<Icon type="plus" />
									{t(
										'users.components.usersTableControls.addUser'
									)}
								</Button>
							</Dropdown>
						)}
					</>
				) : (
					<>
						{USER_ROWS_ACTIONS.DELETED.map(
							(action, index) =>
								action.visible && (
									<Tooltip
										key={index}
										title={action.tooltip}
										enabled={isActionDisabled}
									>
										<Button
											shape="round"
											ghost
											type="primary"
											onClick={action.onClick}
											disabled={isActionDisabled}
										>
											{action.content}
										</Button>
									</Tooltip>
								)
						)}

						{isExportPermitted && (
							<UsersExportButton
								params={props.params}
								disabled={!props.hasData}
							/>
						)}
					</>
				)}
			</Space>
			<UsersTableControlsModal
				{...modalProps}
				currentAction={currentAction}
				currentActionLoading={currentActionLoading}
				onConfirm={onConfirm}
				onCancel={onCancel}
			/>
		</>
	);
});

const UsersTableControlsContainer = (props: UsersTableControlsProps) => (
	<ModalContextProvider>
		<UsersTableControls {...props} />
	</ModalContextProvider>
);

export default UsersTableControlsContainer;
