import { FC, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Input, Select, Layout, FormItem } from 'Components';
import { useFormikContext } from 'formik';
import { GET_GROUP } from 'Services/Api/Groups/Queries';
import { GetGroupResponse } from 'Services/Api/Groups/Types';
import {
	GROUP_ACTIVE_STATUS_LIST,
	GROUP_ACTIVE_STATUS_LABEL_MAP
} from 'app-config';
import { ArchivedField, GroupField, AutoactivateField, SsoField } from 'Pages/Group/AddGroup/components';
import { createInitialValuesDerivedFromParent } from '../../EditGroup.helpers';
import { useTranslation } from 'react-i18next';
import { EditGroupValues } from 'Pages/Group/Group.types';
import PropertyField from './PropertyField';
interface DetailsProps {
	isTenant: boolean;
	groupId: string;
}

const Details: FC<DetailsProps> = ({ isTenant, groupId }) => {
	const { values, setFieldValue } = useFormikContext<EditGroupValues>();
	const { t } = useTranslation();
	const [
		getParentGroup,
		parentGroupResponse
	] = useLazyQuery<GetGroupResponse>(GET_GROUP);

	useEffect(() => {
		if (parentGroupResponse.data?.getGroup) {
			const {
				tenant,
				parent,
				canBePropertyToAncestors
			} = createInitialValuesDerivedFromParent(
				parentGroupResponse.data.getGroup
			);
			const newValues: Pick<
				EditGroupValues,
				'tenant' | 'parent' | 'parentId' | 'canBePropertyToAncestors'
			> = {
				tenant,
				parent,
				parentId: parent?.id ?? null,
				canBePropertyToAncestors
			};
			setFieldValue('tenant', newValues.tenant);
			setFieldValue('parent', newValues.parent);
			setFieldValue('parentId', newValues.parentId);
			setFieldValue(
				'canBePropertyToAncestors',
				newValues.canBePropertyToAncestors
			);
		}
	}, [parentGroupResponse.data?.getGroup, setFieldValue]);

	const onParentGroupChange = (groupId: string) => {
		getParentGroup({
			variables: {
				id: groupId
			}
		});
	};

	const title = isTenant
		? t('group.editGroup.sections.tenantDetails')
		: t('general');

	return (
		<Layout.List fullHeight>
			<Layout.List.ListItem fullHeight>
				<Layout.List.Section title={title}>
					<Input.FormikField
						name="name"
						label={t(
							isTenant ? 'group.tenantName' : 'group.groupName'
						)}
						required
					/>
					{isTenant && (
						<Input.FormikField
							name="tenantId"
							label={t('group.tenantID')}
							required
						/>
					)}

					{values.parent && (
						<FormItem label={t('group.parentGroup')} required>
							<GroupField
								disabledValue={{
									key: Number(groupId),
									title: values.name
								}}
								initialValue={[
									{
										key: values.parent.id,
										title: values.parent.name
									}
								]}
								onSubmit={(value) => {
									onParentGroupChange(value.key as string);
								}}
							/>
						</FormItem>
					)}
					{!isTenant && (
						<FormItem label={t('group.tenant')} required>
							<Select
								value={{
									key: values.tenant?.id,
									value: values.tenant?.id,
									label: values.tenant?.name
								}}
								labelInValue
								disabled
							/>
						</FormItem>
					)}

					<Input.FormikField
						name="syncCode"
						label={t('group.syncCode')}
					/>
					<PropertyField />
				</Layout.List.Section>
				<Layout.List.Section title={t('group.status')}>
					<Select.FormikField
						name="active"
						label={t('group.status')}
						required
					>
						{GROUP_ACTIVE_STATUS_LIST.map((status) => (
							<Select.Option key={status} value={status}>
								{GROUP_ACTIVE_STATUS_LABEL_MAP[status]()}
							</Select.Option>
						))}
					</Select.FormikField>
					<ArchivedField />
					<AutoactivateField />
					{isTenant && values.autoactivate && (
						<SsoField />
					)}
				</Layout.List.Section>
			</Layout.List.ListItem>
		</Layout.List>
	);
};

export default Details;
