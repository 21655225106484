import {
	Group,
	EditTenantRequest,
	EditGroupRequest,
	GroupDetails
} from 'Services/Api/Groups/Types';
import { EditGroupValues } from '../Group.types';

export const getCanBePropertyToAncestors = (group: Group) =>
	!group.parents.some((parent) => parent.isProperty) && !group.isProperty;

export function createInitialValuesDerivedFromParent(
	group: GroupDetails
): Pick<EditGroupValues, 'tenant' | 'parent' | 'canBePropertyToAncestors'> {
	const { name, id, isTenant, moduleSettings, isProperty } = group;
	const canBePropertyToAncestors = getCanBePropertyToAncestors(group);
	const entity = { name, id, moduleSettings, isProperty };
	if (isTenant) {
		return {
			tenant: entity,
			parent: entity,
			canBePropertyToAncestors
		};
	}

	return {
		tenant: group.parents[0],
		parent: entity,
		canBePropertyToAncestors
	};
}

export function createInitialValues(group: GroupDetails): EditGroupValues {
	return {
		id: group.id,
		name: group.name,
		active: group.active,
		syncCode: group.syncCode === null ? null : group.syncCode.trim(),
		isProperty: group.isProperty,
		moduleEnabled: group.moduleSettings?.moduleEnabled ?? false,
		viewAllCourses: group.moduleSettings?.viewAllCourses ?? false,
		tenantId: group.tenantId,
		parentId: group.parentId,
		tenant: group.parents?.[0],
		parent: group.parents.slice(-1)[0] || null,
		isArchived: group.isArchived,
		canBeProperty: group.canBeProperty,
		canBePropertyToAncestors: getCanBePropertyToAncestors(group),
		systemId: group.systemId,
		autoactivate: group.autoactivate,
		sso: group.sso
	};
}

export function getTenantSubmitValues(
	values: EditGroupValues
): EditTenantRequest['input'] {
	return {
		id: values.id,
		name: values.name,
		tenantId: values.tenantId,
		active: values.active,
		syncCode: values.syncCode || null,
		isProperty: values.isProperty,
		isArchived: values.isArchived,
		moduleEnabled: values.moduleEnabled,
		viewAllCourses: values.viewAllCourses,
		autoactivate: values.autoactivate,
		sso: values.autoactivate ? values.sso : false
	};
}

export function getGroupSubmitValues(
	values: EditGroupValues
): EditGroupRequest['input'] {
	return {
		id: values.id,
		parentId: values.parentId,
		name: values.name,
		active: values.active,
		syncCode: values.syncCode || null,
		isProperty: values.isProperty,
		isArchived: values.isArchived,
		moduleEnabled: values.moduleEnabled,
		autoactivate: values.autoactivate,
		sso: false
	};
}
